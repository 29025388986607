import React from 'react'
import { Grid, Header, List, Table } from 'semantic-ui-react'

import Layout from '../components/layout'
import {
  LayoutSection,
  primaryLayoutColor,
  secondaryLayoutColor,
} from '../components/layout-components'

const ProjectsPage = () => (
  <Layout>
    {/* Bayer Dashboard */}
    <LayoutSection color={primaryLayoutColor} text>
      <Grid>
        <Grid.Row>
          <Header dividing>
            Entwicklung eines Dashboards zur Produktions&uuml;berwachung im
            Chemiesektor
          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={4} mobile={4}>
            <Header size="small">Zeitraum:</Header>
          </Grid.Column>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <p>03/20 – 04/20</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <Header size="small">Problemstellung:</Header>
          </Grid.Column>
          <Grid.Column
            computer={12}
            tablet={16}
            mobile={16}
            textAlign="justified"
          >
            <p>
              Es sollte ein Dashboard erstellt werden, das die wichtigsten
              Kennzahlen an einer Produktionsstra&szlig;e anzeigt. Dabei sollten
              Live-Daten angezeigt werden und visuell so aufbereitet werden,
              dass ein schneller &Uuml;berblick der aktuelle Situation
              gew&auml;hrleistet ist.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <Header size="small">Meine Aufgaben:</Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <List bulleted relaxed>
              <List.Item>
                Verarbeitung der Daten, die in JSON bereitgestellt worden sind.
              </List.Item>
              <List.Item>
                &Uuml;bertragung des Designs in SVG in HTML-Widgets mit Hilfe
                der D3.js Bibliothek.
              </List.Item>
              <List.Item>
                Dynamische &Auml;nderungen der Widgets anhand der Daten.
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <Header size="small">Verwendete Technologien:</Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <Table basic="very" collapsing size="small" unstackable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Frameworks / Standards:</Table.Cell>
                  <Table.Cell>D3.js</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Programmier&shy;sprachen:</Table.Cell>
                  <Table.Cell>HTML/Javascript/CSS</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Umgebung:</Table.Cell>
                  <Table.Cell>Web-Server</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </LayoutSection>

    {/* Deloitte oneM2M */}
    <LayoutSection color={secondaryLayoutColor} text>
      <Grid>
        <Grid.Row>
          <Header dividing>
            Umsetzung eines Prototyps zur Evaluierung des oneM2M-Standards im
            Energiesektors (Sammeln der Daten von den Umspannwerken)
          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={4} mobile={4}>
            <Header size="small">Zeitraum:</Header>
          </Grid.Column>
          <Grid.Column computer={4} tablet={12} mobile={12}><p>01/20 – 02/20</p></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <Header size="small">Problemstellung:</Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={16} mobile={16} textAlign="justified">
            <p>
              Zur Evaluierung der Digitalisierung der &Uuml;bertragung der Daten
              von Umspannwerken in eine zentrale Plattform wurde der
              oneM2M-Standard genommen. Es sollten Daten von einzelnen
              Endpunkten abgefragt werden und am Zielort abgelegt werden. Dabei
              wurden die Daten zusammengefasst, &uuml;berpr&uuml;ft und in das
              Zielformat &uuml;berf&uuml;hrt.
            </p>
            <p>
              F&uuml;r diesen Testlauf wurde auf die Daten nur mittels FTP
              zugegriffen. Jedoch konnte gezeigt werden, dass andere
              Zugriffstechnologien ebenso m&ouml;glich sind. Hierf&uuml;r wurden
              Adapter geschrieben, welche die Umwandlung der Daten von den
              Endpunkten in die IoT-Plattform und in die Zielplattform
              zur&uuml;ck &uuml;bernehmen. Alle Bestandteile wurden in
              Docker-Containern bereitgestellt, um ein dynamisches Management zu
              erm&ouml;glichen.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <Header size="small">Meine Aufgaben:</Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <List bulleted relaxed>
              <List.Item>
                Anpassungen an der IoT-Plattform (
                <a href="https://www.openmtc.org/">OpenMTC</a>).
              </List.Item>
              <List.Item>
                Bereitstellen der Adapter zur Interaktion zwischen FTP und der
                IoT-Plattform.
              </List.Item>
              <List.Item>Containerisierung (Docker) der Komponenten.</List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <Header size="small">Verwendete Technologien:</Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <Table basic="very" collapsing size="small" unstackable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Frameworks / Standards:</Table.Cell>
                  <Table.Cell>oneM2M</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Programmier&shy;sprachen:</Table.Cell>
                  <Table.Cell>Python</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Umgebung:</Table.Cell>
                  <Table.Cell>Docker-Container</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </LayoutSection>
  </Layout>
)

export default ProjectsPage
